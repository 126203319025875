import { minLength, required, requiredIf, sameAs } from 'vuelidate/lib/validators';
import { password_regex } from '@/assets/validators/regex_validator';

export const password_validator = {
  password: {
    required,
    minLen: minLength(8),
    is_valid(password) {
      const { regex } = password_regex;
      return this.is_text_valid(password, regex);
    },
  },

  confirmed_pass: { sameAs: sameAs((vm) => vm.password) },

  old_password: {
    requiredIf: requiredIf(function () {
      return this.is_periodic_change;
    }),
  },
};
