<template>
  <vue-fragment>
    <div v-if="is_periodic_change"
         :class="styling.outer_div">
      <div :class="styling.inner_div">
        <label for="old_password">
          Old password*
        </label>
        <input id="old_password"
               v-model="old_password"
               class="form-control"
               name="password"
               type="password"
               @input="$v.old_password.$touch">
        <label v-if="$v.old_password.$invalid"
               class="hint red--text"
               for="old_password">
          Please enter the old password
        </label>
        <label v-else
               class="hint"
               for="old_password">&nbsp;</label>
      </div>
    </div>

    <div :class="styling.outer_div">
      <div :class="styling.inner_div">
        <label for="registration_password">
          Password*
        </label>
        <input id="registration_password"
               v-model="password"
               class="form-control"
               name="password"
               type="password"
               @input="$v.password.$touch">
        <label v-if="!$v.password.minLen || $v.password.$invalid"
               class="hint red--text"
               for="registration_password">
          <span v-for="(placeholder, key) in placeholders.password_help_placeholder"
                v-show="!is_text_valid(password, new RegExp(key))"
                :key="key"
                style="display: block"
                v-html="placeholder">
          </span>
        </label>
        <label v-else
               class="teal--text"
               for="registration_password">Correct</label>
      </div>
    </div>

    <div :class="styling.outer_div">
      <div :class="styling.inner_div">
        <label for="registration_password_confirmation">
          Password confirmation*
        </label>
        <input id="registration_password_confirmation"
               v-model="confirmed_pass"
               class="form-control"
               name="password_confirmation"
               type="password"
               @input="$v.confirmed_pass.$touch">
        <label v-if="!$v.confirmed_pass.sameAs && $v.confirmed_pass.$dirty"
               class="red--text">
          Must match given password
        </label>
        <label v-else-if="$v.confirmed_pass.sameAs && $v.confirmed_pass.$dirty"
               class="teal--text"
               for="registration_password_confirmation">
          Correct
        </label>
      </div>
    </div>
  </vue-fragment>
</template>

<script>
  import placeholders from '@/json/placeholders';
  import { password_validator } from '@/assets/validators/user_password_validator';

  export default {
    name: 'user_password',
    props: {
      styling: {
        type: Object,
        default: () => ({}),
      },
      is_periodic_change: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        confirmed_pass: '',
        placeholders,
        password: '',
        old_password: '',
      };
    },
    validations: password_validator,
    watch: {
      '$v.$invalid': function (val) {
        this.$emit('is_valid', val);
      },
      password() {
        this.$emit('input', this.password);
      },
      old_password() {
        this.$emit('old_password', this.old_password);
      },
    },
  };
</script>

<style scoped>

  label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-size: 1rem;
    font-family: Arial, 'Avenir', Helvetica, sans-serif;
    font-weight: bold;
    color: black;
  }

  .input.invalid input {
    border: 1px solid red;
    background-color: #ffefa3;
  }

  .hint {
    font-size: 1em !important;
  }

</style>
