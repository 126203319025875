<template>
  <div class="solaris-bg">
    <b-jumbotron bg-variant="light"
                 class="password-form">
      <b-row>
        <b-col class="ma-4">
          <b-container>
            <form @submit.prevent>
              <b-row class="pl-3">
                <h3> {{ header }} </h3>
              </b-row>

              <user_password is_periodic_change
                             @input="new_password = $event"
                             @is_valid="pass_invalid = $event"
                             @old_password="old_password = $event"/>
              <b-row>
                <b-col>
                  <b-button :disabled="disable || pass_invalid"
                            class="button-before-login"
                            type="submit"
                            @click="sendNewPassword">
                    Change my password
                  </b-button>
                </b-col>
              </b-row>
            </form>
          </b-container>
        </b-col>
      </b-row>
    </b-jumbotron>
  </div>
</template>

<script>
  import user_password from '@/components/user_password';

  export default {
    data() {
      return {
        pass_invalid: true,
        disable: false,
        is_periodic_change: true,
        new_password: '',
        old_password: '',
        header: '',
      };
    },
    components: { user_password },
    methods: {
      sendNewPassword() {
        this.disable = true;
        const sign_in_data = {
          email: this.$store.getters['login/getLoggedUserAttribute']('email'),
          new_password: this.new_password,
        };

        if (!this.pass_invalid) {
          this.axios.put('password/expired', {
            ...sign_in_data,
            password: this.old_password,
          })
            .then((resp) => {
              if (Object.keys(resp.data).includes('msg')) {
                this.$notify({ type: 'success', title: resp.data.msg });
                return this.login(sign_in_data);
              }
              return Promise.reject(resp);
            })
            .catch((resp) => {
              this.disable = false;
              if (this.$store.getters['login/getLoggedUserAttribute']('email')) {
                const passwordError = resp.data.error === 'wrong password' ? 'Wrong old password' : resp.data.error;
                this.$notify({ type: 'error', title: passwordError });
              } else {
                this.$notify({
                  duration: 10000,
                  type: 'error',
                  title: 'Something went wrong. Provide your login details again to change your password',
                });
                this.$router.push('/login');
              }
            });
        } else {
          this.$notify({ type: 'error', title: 'Passwords don\'t match or bad format' });
          this.disable = false;
        }
      },
      login(sign_in_data) {
        this.$store.dispatch('login/login', {
          email: sign_in_data.email,
          password: sign_in_data.new_password,
        })
          .then(() => {
            this.$router.push('/home');
          })
          .catch((err) => {
            this.$notify({ type: 'error', title: err.error });
            this.$router.push('/login');
          });
      },
    },
    mounted() {
      this.$store.commit('login/restrict', true);
      if (this.$route.params.type === 'expired') {
        this.header = 'Your password has expired. You must change it before logging in.';
      } else {
        this.header = 'Change password';
      }
    },
    destroyed() {
      this.$store.commit('login/restrict', false);
    },
  };
</script>

<style lang="scss"
       scoped>

  .jumbotron {
    margin-top: 15vh !important;
    background: rgba(245, 245, 245, 0.75) !important;
  }

  h3 {
    font-family: Arial, 'Avenir', Helvetica, sans-serif;
    font-weight: bold;
    color: $color-blue-dark;
  }

  .container {
    padding: 0;
  }

  .password-form {
    padding: 0 !important;
    margin-left: 25% !important;
    margin-right: 25% !important;
    margin-top: 30px;
    @media screen and (max-width: 700px) {
      padding: 0 !important;
      margin-left: 10% !important;
      margin-right: 10% !important;
      margin-top: 30px;
    }
  }
</style>
